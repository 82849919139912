import React, { ReactNode } from 'react';

import './styles.css';

interface Proptypes {
  children: ReactNode;
  colorClass?: 'text-primary' | 'text-dark' | 'text-white' | 'text-gold';
  capitalize?: boolean;
}

export const Title: React.FC<Proptypes> = ({ children, colorClass }) => {
  return <h1 className={`title ${colorClass}`}>{children}</h1>;
};

export const Heading: React.FC<Proptypes> = ({ children, colorClass }) => {
  return <h1 className={`heading ${colorClass}`}>{children}</h1>;
};

export const Text: React.FC<Proptypes> = ({
  children,
  colorClass,
  capitalize,
}) => {
  return (
    <p
      className={`text ${colorClass || ''} ${capitalize && 'capitalize-text'}`}
    >
      {children}
    </p>
  );
};

export const SmallText: React.FC<Proptypes> = ({
  children,
  colorClass,
  capitalize,
}) => {
  return (
    <p
      className={`text small-text ${capitalize && 'capitalize-text'} ${
        colorClass || ''
      }`}
    >
      {children}
    </p>
  );
};

export const InfoText: React.FC<Proptypes> = ({ children, colorClass }) => {
  return <p className={`text info-text ${colorClass || ''}`}>{children}</p>;
};

export const Subheader: React.FC<Proptypes> = ({ children, colorClass }) => {
  return <h3 className={`subheader ${colorClass}`}>{children}</h3>;
};
