import React, { FC } from 'react';
import './styles.css';

interface Proptypes {
  videoId: string;
  handleClose?: () => void;
}
const VideoPlayer: FC<Proptypes> = ({ videoId, handleClose }) => {
  return (
    <div className="video">
      <div className="video-back-action">
        <i className="material-icons" onClick={handleClose}>
          keyboard_backspace
        </i>
      </div>
      <iframe
        title="video"
        className="video-player"
        frameBorder="0"
        allowFullScreen={true}
        src={`https://www.youtube.com/embed/${videoId}?rel=0`}
      />
    </div>
  );
};

export default VideoPlayer;
