import React, { ChangeEvent, FC, FormEvent, useState } from 'react';

import Button from '../Button';
import { User } from '../../Models/user';
import { Text, InfoText } from '../Typography';
import './styles.css';

interface Proptypes {
  handleSubmit: (data: User) => void;
}

const Form: FC<Proptypes> = ({ handleSubmit }) => {
  const [data, setData] = useState<User>({
    name: '',
    email: '',
    subscriber: true,
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const copyData = { ...data };
    const target = event.target;
    copyData[target.name] =
      target.type === 'checkbox' ? target.checked : target.value;

    setData(copyData);
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSubmit(data);
  };

  return (
    <div className="form-root">
      <Text>
        Fill in your details (just this once!) to receive your chosen recipes
      </Text>
      <form className="form" onSubmit={onSubmit}>
        <div className="input-container">
          <input
            name="name"
            value={data.name}
            onChange={handleChange}
            placeholder="Full Name"
            type="text"
            required={true}
          />
        </div>

        <div className="input-container">
          <input
            name="email"
            value={data.email}
            onChange={handleChange}
            placeholder="Email"
            type="email"
            required={true}
          />
        </div>
        <div className="form-dropdown">
          <input
            name="subscriber"
            onChange={handleChange}
            checked={data.subscriber}
            type="checkbox"
          />
          <InfoText>
            By ticking this box you agree to receive brand promo & marketing
            information
          </InfoText>
        </div>
        <Button type="submit">Send</Button>
      </form>
    </div>
  );
};

export default Form;
