import { Cocktail } from '../../Models/cocktail';
import { User } from '../../Models/user';
const EMAIL_BODY =
  'Thanks for playing Cocktail Finder. We hope you enjoyed it bae bee! Please find your cocktail recipe attached & have fun with your perfect match.';

const displayEmail = (user: User, cocktail: Cocktail) => `
<head>
  <title>Durbanville Hills Cocktail Finder | Find your match!</title>
  <meta charset="utf-8" />
  <meta name="viewport" content="width=device-width" />
  <style type="text/css">
    /* CLIENT-SPECIFIC STYLES */
    #outlook a {
      padding: 0;
    } /* Force Outlook to provide a "view in browser" message */
    .ReadMsgBody {
      width: 100%;
    }
    .ExternalClass {
      width: 100%;
    } /* Force Hotmail to display emails at full width */
    .ExternalClass,
    .ExternalClass p,
    .ExternalClass span,
    .ExternalClass font,
    .ExternalClass td,
    .ExternalClass div {
      line-height: 100%;
    } /* Force Hotmail to display normal line spacing */
    body,
    table,
    td,
    a {
      -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
    } /* Prevent WebKit and Windows mobile changing default text sizes */
    table,
    td {
      mso-table-lspace: 0pt;
      mso-table-rspace: 0pt;
    } /* Remove spacing between tables in Outlook 2007 and up */
    img {
      -ms-interpolation-mode: bicubic;
    } /* Allow smoother rendering of resized image in Internet Explorer */

    /* RESET STYLES */
    body {
      margin: 0;
      padding: 0;
    }
    img {
      border: 0;
      height: auto;
      line-height: 100%;
      outline: none;
      text-decoration: none;
    }
    table {
      border-collapse: collapse !important;
    }
    body {
      height: 100% !important;
      margin: 0;
      padding: 0;
      width: 100% !important;
    }

    img[class='match-faces'] {
      display: block !important;
      width: 80% !important;
      height: auto !important;
    }

    h1 {
      font-size: 1.5em;
      margin-bottom: 0px;
    }

    h3 {
      font-size: 25px;
      font-weight: 400;

      margin: 0px;
    }

    p {
      font-size: 25px;
    }
    .finder-logo {
      padding: 50px 0 0 0;
    }

    /* iOS BLUE LINKS */
    .appleBody a {
      color: #8c7745;
      text-decoration: none;
    }
    .appleFooter a {
      color: #999999;
      text-decoration: none;
    }

    /* FULL-WIDTH TABLES */
    table[class='hero-table'] {
      width: 100% !important;
    }

    td[class='finder-logo'] {
      padding: 50px 16px 20px;
    }

    /* MOBILE STYLES */
    @media screen and (max-width: 525px) {
      /* ALLOWS FOR FLUID TABLES */
      table[class='wrapper'] {
        width: 100% !important;
      }

      /* ADJUSTS LAYOUT OF LOGO IMAGE */
      td[class='logo'] {
        text-align: left;
        padding: 20px 0 20px 0 !important;
      }

      td[class='logo'] img {
        margin: 0 auto !important;
      }

      /* USE THESE CLASSES TO HIDE CONTENT ON MOBILE */
      td[class='mobile-hide'] {
        display: none;
      }

      img[class='mobile-hide'] {
        display: none !important;
      }

      img[class='img-max'] {
        max-width: 100% !important;
        height: auto !important;
      }

      /* FULL-WIDTH TABLES  */
      table[class='responsive-table'] {
        width: 100% !important;
      }

      /* UTILITY CLASSES FOR ADJUSTING PADDING ON MOBILE */
      td[class='padding'] {
        padding: 10px 5% 15px 5% !important;
      }

      td[class='padding-copy'] {
        padding: 10px 5% 10px 5% !important;
        text-align: center;
      }

      td[class='padding-meta'] {
        padding: 30px 5% 0px 5% !important;
        text-align: center;
      }

      td[class='no-pad'] {
        padding: 0 0 20px 0 !important;
      }

      td[class='no-padding'] {
        padding: 0 !important;
      }

      td[class='section-padding'] {
        padding: 50px 15px 50px 15px !important;
      }

      td[class='section-padding-bottom-image'] {
        padding: 50px 15px 0 15px !important;
      }

      /* ADJUST BUTTONS ON MOBILE
        td[class='mobile-wrapper'] {
          padding: 10px 5% 15px 5% !important;
        } */

      table[class='mobile-button-container'] {
        margin: 0 auto;
        width: 100% !important;
      }

      a[class='mobile-button'] {
        width: 80% !important;
        padding: 15px !important;
        border: 0 !important;
        font-size: 16px !important;
      }
    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 320px) {
      img[class='match-faces'] {
        width: 80% !important;
        height: auto !important;
      }
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {
      h1 {
        font-size: 2em;
        margin-bottom: 20px;
      }

      h3 {
        font-size: 35px;
      }

      img[class='match-faces'] {
        width: 80% !important;
        height: 80% !important;
      }

      p {
        font-size: 30px;
      }
    }

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 992px) {
      div[class='match-faces'] {
        width: 80% !important;
        height: auto !important;
        border: solid 10px rgb(240 240 240) !important;
      }

      /* FULL-WIDTH TABLES */
      table[class='hero-table'] {
        width: 1000px !important;
      }
    }

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {
    }
  </style>
</head>
<body
  style="
    margin: 0;
    padding: 0;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    height: 100% !important;
    width: 100% !important;
  "
>
  <!-- ONE COLUMN SECTION -->
  <table
    border="0"
    cellpadding="0"
    cellspacing="0"
    width="100%"
    style="
      table-layout: fixed;
      -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
      mso-table-lspace: 0pt;
      mso-table-rspace: 0pt;
      border-collapse: collapse !important;
    "
  >
    <tr>
      <td
        bgcolor="#ffffff"
        align="center"
        style="
          padding: 70px 15px 0px 15px;
          -webkit-text-size-adjust: 100%;
          -ms-text-size-adjust: 100%;
          mso-table-lspace: 0pt;
          mso-table-rspace: 0pt;
        "
        class="section-padding"
      >
        <table
          border="0"
          cellpadding="0"
          cellspacing="0"
          width="1000"
          class="hero-table"
          style="
            -webkit-text-size-adjust: 100%;
            -ms-text-size-adjust: 100%;
            mso-table-lspace: 0pt;
            mso-table-rspace: 0pt;
            border-collapse: collapse !important;
            width: 100% !important;
          "
        >
          <tr>
            <td
              style="
                -webkit-text-size-adjust: 100%;
                -ms-text-size-adjust: 100%;
                mso-table-lspace: 0pt;
                mso-table-rspace: 0pt;
              "
            >
              <table
                width="100%"
                border="0"
                cellspacing="0"
                cellpadding="0"
                style="
                  -webkit-text-size-adjust: 100%;
                  -ms-text-size-adjust: 100%;
                  mso-table-lspace: 0pt;
                  mso-table-rspace: 0pt;
                  border-collapse: collapse !important;
                "
              >
                <tr>
                  <td
                    style="
                      -webkit-text-size-adjust: 100%;
                      -ms-text-size-adjust: 100%;
                      mso-table-lspace: 0pt;
                      mso-table-rspace: 0pt;
                    "
                  >
                    <!-- HERO IMAGE -->
                    <table
                      width="100%"
                      border="0"
                      cellspacing="0"
                      cellpadding="0"
                      style="
                        -webkit-text-size-adjust: 100%;
                        -ms-text-size-adjust: 100%;
                        mso-table-lspace: 0pt;
                        mso-table-rspace: 0pt;
                        border-collapse: collapse !important;
                      "
                    >
                      <tbody>
                        <tr>
                          <td
                            class="padding-copy"
                            style="
                              -webkit-text-size-adjust: 100%;
                              -ms-text-size-adjust: 100%;
                              mso-table-lspace: 0pt;
                              mso-table-rspace: 0pt;
                            "
                          >
                            <table
                              width="100%"
                              border="0"
                              cellspacing="0"
                              cellpadding="0"
                              style="
                                -webkit-text-size-adjust: 100%;
                                -ms-text-size-adjust: 100%;
                                mso-table-lspace: 0pt;
                                mso-table-rspace: 0pt;
                                border-collapse: collapse !important;
                              "
                            >
                              <tr>
                                <td
                                  align="center"
                                  style="
                                    -webkit-text-size-adjust: 100%;
                                    -ms-text-size-adjust: 100%;
                                    mso-table-lspace: 0pt;
                                    mso-table-rspace: 0pt;
                                  "
                                >
                                  <a
                                    href="${process.env.REACT_APP_LIVE_URL}/cocktail"
                                    target="_blank"
                                    style="
                                      -webkit-text-size-adjust: 100%;
                                      -ms-text-size-adjust: 100%;
                                    "
                                    ><img
                                      src="https://firebasestorage.googleapis.com/v0/b/dvh-cocktail-tinder.appspot.com/o/app%2Fdvh-logo.png?alt=media&token=65b1c5a8-ab4e-4f9b-865f-1a12d7330ba4"
                                      width="150"
                                      border="0"
                                      alt="${cocktail.title}"
                                      style="
                                        display: block;
                                        padding: 0;
                                        color: #666666;
                                        text-decoration: none;
                                        font-family: Helvetica, arial,
                                          sans-serif;
                                        font-size: 16px;
                                        width: 150px;
                                        -ms-interpolation-mode: bicubic;
                                        border: 0;
                                        height: auto;
                                        line-height: 100%;
                                        outline: none;
                                      "
                                      class="img-max"
                                  /></a>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>

                <tr>
                  <td
                    style="
                      -webkit-text-size-adjust: 100%;
                      -ms-text-size-adjust: 100%;
                      mso-table-lspace: 0pt;
                      mso-table-rspace: 0pt;
                    "
                  >
                    <!-- BULLETPROOF BUTTON -->
                    <table
                      width="100%"
                      border="0"
                      cellspacing="0"
                      cellpadding="0"
                      class="mobile-button-container"
                      style="
                        -webkit-text-size-adjust: 100%;
                        -ms-text-size-adjust: 100%;
                        mso-table-lspace: 0pt;
                        mso-table-rspace: 0pt;
                        border-collapse: collapse !important;
                      "
                    >
                      <tr>
                        <td
                          align="center"
                          class="finder-logo"
                          style="
                            padding: 50px 16px 20px;
                            -webkit-text-size-adjust: 100%;
                            -ms-text-size-adjust: 100%;
                            mso-table-lspace: 0pt;
                            mso-table-rspace: 0pt;
                          "
                        >
                          <table
                            border="0"
                            cellspacing="0"
                            cellpadding="0"
                            style="
                              -webkit-text-size-adjust: 100%;
                              -ms-text-size-adjust: 100%;
                              mso-table-lspace: 0pt;
                              mso-table-rspace: 0pt;
                              border-collapse: collapse !important;
                            "
                          >
                            <tr>
                              <td
                                align="center"
                                style="
                                  -webkit-text-size-adjust: 100%;
                                  -ms-text-size-adjust: 100%;
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                "
                              >
                                <img
                                  src="https://firebasestorage.googleapis.com/v0/b/dvh-cocktail-tinder.appspot.com/o/app%2Ffinder-logo.png?alt=media&token=7a062f12-3e49-4c8d-9d43-1912efb318e3"
                                  width="400"
                                  border="0"
                                  alt="${cocktail.title}"
                                  style="
                                    display: block;
                                    padding: 0;
                                    color: #666666;
                                    text-decoration: none;
                                    font-family: Helvetica, arial, sans-serif;
                                    font-size: 16px;
                                    width: 400px;
                                    border: 0;
                                    height: auto;
                                    line-height: 100%;
                                    outline: none;
                                  "
                                  class="img-max"
                                />
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td
                    style="
                      -webkit-text-size-adjust: 100%;
                      -ms-text-size-adjust: 100%;
                      mso-table-lspace: 0pt;
                      mso-table-rspace: 0pt;
                    "
                  >
                    <!-- COPY -->
                    <table
                      width="80%"
                      align="center"
                      border="0"
                      cellspacing="0"
                      cellpadding="0"
                      style="
                        -webkit-text-size-adjust: 100%;
                        -ms-text-size-adjust: 100%;
                        mso-table-lspace: 0pt;
                        mso-table-rspace: 0pt;
                        border-collapse: collapse !important;
                      "
                    >
                    <tr>
                    <td
                      align="center"
                      class="padding-copy"
                      style="
                        -webkit-text-size-adjust: 100%;
                        -ms-text-size-adjust: 100%;
                        mso-table-lspace: 0pt;
                        mso-table-rspace: 0pt;
                        padding-top: 50px;
                      "
                    >
                      <p
                        style="
                          font-size: 18px;
                          font-family: Helvetica, Arial, sans-serif;
                          color: #848484;
                          font-weight: bold;
                          margin: 0px;
                        "
                      >
                       ${EMAIL_BODY}
                      </p>
                    </td>
                  </tr>
                      <tr>
                        <td
                          align="center"
                          style="
                            font-size: 30px;
                            font-family: Helvetica, Arial, sans-serif;
                            color: #8c7745;
                            padding-top: 50px;
                            text-transform: uppercase;
                            -webkit-text-size-adjust: 100%;
                            -ms-text-size-adjust: 100%;
                            mso-table-lspace: 0pt;
                            mso-table-rspace: 0pt;
                          "
                          class="padding-copy"
                        >
                          <h1 style="font-size: 30px; margin-bottom: 0px">
                            It's a match!
                          </h1>
                        </td>
                      </tr>
                      <tr>
                      <td
                        align="center"
                        class="padding-copy"
                        style="
                          -webkit-text-size-adjust: 100%;
                          -ms-text-size-adjust: 100%;
                          mso-table-lspace: 0pt;
                          mso-table-rspace: 0pt;
                        "
                      >
                      <p
                      style="
                        font-size: 18px;
                        font-family: Helvetica, Arial, sans-serif;
                        color: #848484;
                        font-weight: 400;
                        margin: 0px;
                      "
                    >
                     ${cocktail.email.subject}
                    </p>
                        <p
                          style="
                            font-size: 18px;
                            font-family: Helvetica, Arial, sans-serif;
                            color: #848484;
                            font-weight: 400;
                            margin: 0px;
                          "
                        >
                          ${cocktail.email.body}
                        </p>
                      </td>
                    </tr>
                      <tr>
                        <td
                          style="
                            -webkit-text-size-adjust: 100%;
                            -ms-text-size-adjust: 100%;
                            mso-table-lspace: 0pt;
                            mso-table-rspace: 0pt;
                          "
                        >
                          <!-- TWO COLUMNS -->
                          <table
                            cellspacing="0"
                            cellpadding="0"
                            border="0"
                            align="center"
                            style="
                              position: relative;
                              -webkit-text-size-adjust: 100%;
                              -ms-text-size-adjust: 100%;
                              mso-table-lspace: 0pt;
                              mso-table-rspace: 0pt;
                              border-collapse: collapse !important;
                            "
                          >
                            <tr>
                              <td
                                valign="top"
                                style="
                                  padding: 40px 0 0 0;
                                  -webkit-text-size-adjust: 100%;
                                  -ms-text-size-adjust: 100%;
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                "
                                class="mobile-wrapper"
                              >
                                <!-- RIGHT COLUMN -->
                                <table
                                  cellpadding="0"
                                  cellspacing="0"
                                  border="0"
                                  align="right"
                                  style="
                                    -webkit-text-size-adjust: 100%;
                                    -ms-text-size-adjust: 100%;
                                    mso-table-lspace: 0pt;
                                    mso-table-rspace: 0pt;
                                    border-collapse: collapse !important;
                                  "
                                >
                                  <tr>
                                    <td
                                      style="
                                        -webkit-text-size-adjust: 100%;
                                        -ms-text-size-adjust: 100%;
                                        mso-table-lspace: 0pt;
                                        mso-table-rspace: 0pt;
                                      "
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        border="0"
                                        width="100%"
                                        style="
                                          -webkit-text-size-adjust: 100%;
                                          -ms-text-size-adjust: 100%;
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse !important;
                                        "
                                      >
                                        <tr>
                                          <td
                                            align="center"
                                            bgcolor="#ffffff"
                                            valign="middle"
                                            style="
                                              -webkit-text-size-adjust: 100%;
                                              -ms-text-size-adjust: 100%;
                                              mso-table-lspace: 0pt;
                                              mso-table-rspace: 0pt;
                                            "
                                          >
                                            <img
                                              class="match-face"
                                              src="${cocktail.matchImage}"
                                              alt="${cocktail.title}"
                                              border="0"
                                              width="200"
                                              alt="Can an email really be responsive?"
                                              style="
                                                margin: 5px;
                                                display: block;
                                                width: 200px !important;
                                                height: auto;
                                                -ms-interpolation-mode: bicubic;
                                                line-height: 100%;
                                                outline: none;
                                                text-decoration: none;
                                              "
                                            />
                                          </td>
                                        </tr>
                                      </table>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <!-- Video Copy Here begins -->

                      <tr>
                        <td
                          align="center"
                          style="
                            font-size: 30px;
                            font-family: Helvetica, Arial, sans-serif;
                            color: #8c7745;
                            padding-top: 50px;
                            text-transform: uppercase;
                            -webkit-text-size-adjust: 100%;
                            -ms-text-size-adjust: 100%;
                            mso-table-lspace: 0pt;
                            mso-table-rspace: 0pt;
                          "
                          class="padding-copy"
                        >
                          <h1 style="font-size: 25px; margin-bottom: 0px">
                            Watch ${cocktail.title}'s video below
                          </h1>
                        </td>
                      </tr>

                      <tr>
                        <td
                          style="
                            -webkit-text-size-adjust: 100%;
                            -ms-text-size-adjust: 100%;
                            mso-table-lspace: 0pt;
                            mso-table-rspace: 0pt;
                          "
                        >
                          <table
                            cellspacing="0"
                            cellpadding="0"
                            border="0"
                            align="center"
                            style="
                              position: relative;
                              -webkit-text-size-adjust: 100%;
                              -ms-text-size-adjust: 100%;
                              mso-table-lspace: 0pt;
                              mso-table-rspace: 0pt;
                              border-collapse: collapse !important;
                            "
                          >
                            <tr>
                              <td
                                valign="top"
                                style="
                                  padding: 40px 0 0 0;
                                  -webkit-text-size-adjust: 100%;
                                  -ms-text-size-adjust: 100%;
                                  mso-table-lspace: 0pt;
                                  mso-table-rspace: 0pt;
                                "
                                class="mobile-wrapper"
                              >
                                <!-- Video Here -->

                                <table
                                  cellpadding="0"
                                  cellspacing="0"
                                  border="0"
                                  align="right"
                                  style="
                                    -webkit-text-size-adjust: 100%;
                                    -ms-text-size-adjust: 100%;
                                    mso-table-lspace: 0pt;
                                    mso-table-rspace: 0pt;
                                    border-collapse: collapse !important;
                                  "
                                >
                                  <tr>
                                    <td
                                      style="
                                        padding: 20px 0 40px 0;
                                        -webkit-text-size-adjust: 100%;
                                        -ms-text-size-adjust: 100%;
                                        mso-table-lspace: 0pt;
                                        mso-table-rspace: 0pt;
                                      "
                                    >
                                      <table
                                        cellpadding="0"
                                        cellspacing="0"
                                        border="0"
                                        width="100%"
                                        style="
                                          -webkit-text-size-adjust: 100%;
                                          -ms-text-size-adjust: 100%;
                                          mso-table-lspace: 0pt;
                                          mso-table-rspace: 0pt;
                                          border-collapse: collapse !important;
                                        "
                                      >
                                        <tr>
                                          <td
                                            align="center"
                                            bgcolor="#ffffff"
                                            valign="middle"
                                            style="
                                              -webkit-text-size-adjust: 100%;
                                              -ms-text-size-adjust: 100%;
                                              mso-table-lspace: 0pt;
                                              mso-table-rspace: 0pt;
                                            "
                                          >
                                            <a
                                              href="https://www.youtube.com/watch?v=${cocktail.video}"
                                              target="_blank"
                                            >
                                              <img
                                                class="match-faces"
                                                src="${cocktail.videoThumb}"
                                                alt="${cocktail.title} thumb here"
                                                border="0"
                                                style="
                                                  margin: 5px;
                                                  display: block;
                                                  cursor: pointer;
                                                "
                                              />
                                            </a>
                                          </td>
                                        </tr>
                                      </table>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td
                          align="center"
                          style="
                            font-size: 20px;
                            font-family: Helvetica, Arial, sans-serif;
                            color: #846e4d;
                            padding-top: 50px;
                            font-weight: bold;
                            -webkit-text-size-adjust: 100%;
                            -ms-text-size-adjust: 100%;
                            mso-table-lspace: 0pt;
                            mso-table-rspace: 0pt;
                          "
                          class="padding-copy"
                        >
                          <p
                            class="sub-header"
                            style="
                              margin: 0px 0px 10px 0px;
                              text-transform: uppercase;
                              font-size: 20px;
                            "
                          >
                            Follow us for more
                          </p>
                          <p
                            class="sub-header"
                            style="margin: 0px; font-size: 18px"
                          >
                            #HeyHoneysuckle <span role="img" aria-label="yellow-heart">
                            💛
                          </span> <span role="img" aria-label="bee">
                            🐝
                          </span> #DrinkDHResponsibly
                          </p>
                          <a
                            href="https://www.durbanvillehills.co.za/"
                            target="_blank"
                            style="
                              color: #846e4d;
                              -webkit-text-size-adjust: 100%;
                              -ms-text-size-adjust: 100%;
                            "
                          >
                            <p
                              class="sub-header"
                              style="
                                margin: 0px;
                                font-size: 12px;
                                color: #846e4d;
                              "
                            >
                              www.durbanvillehills.com
                            </p>
                          </a>
                          <a
                            href="https://web.facebook.com/DurbanvilleHills"
                            target="_blank"
                            style="
                              -webkit-text-size-adjust: 100%;
                              -ms-text-size-adjust: 100%;
                              width: 20px;
                              color: #ffffff;
                              text-decoration: none;
                            "
                          >
                            <img src="https://firebasestorage.googleapis.com/v0/b/dvh-cocktail-tinder.appspot.com/o/email%2Ffacebook-icon.png?alt=media&token=46d98814-c40c-48fd-89f3-3106f514d25c" alt="Facebook" width="20" />
                          </a>
                          <a
                            href="https://www.instagram.com/DurbanvilleHills/"
                            target="_blank"
                            style="
                              -webkit-text-size-adjust: 100%;
                              -ms-text-size-adjust: 100%;
                              margin-left: 5px;
                              width: 20px;
                              color: #ffffff;
                              text-decoration: none;
                            "
                          >
                            <img src="https://firebasestorage.googleapis.com/v0/b/dvh-cocktail-tinder.appspot.com/o/email%2Finstagram-icon.png?alt=media&token=f95a25e2-d282-4ab0-9675-09650d2ab584" alt="Instagram" width="20" />
                          </a>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>

  <!-- FOOTER -->
  <table
    border="0"
    cellpadding="0"
    cellspacing="0"
    width="100%"
    style="
      table-layout: fixed;
      -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
      mso-table-lspace: 0pt;
      mso-table-rspace: 0pt;
      border-collapse: collapse !important;
    "
  >
    <tr>
      <td
        bgcolor="#ffffff"
        align="center"
        style="
          -webkit-text-size-adjust: 100%;
          -ms-text-size-adjust: 100%;
          mso-table-lspace: 0pt;
          mso-table-rspace: 0pt;
        "
      >
        <table
          width="100%"
          border="0"
          cellspacing="0"
          cellpadding="0"
          align="center"
          style="
            -webkit-text-size-adjust: 100%;
            -ms-text-size-adjust: 100%;
            mso-table-lspace: 0pt;
            mso-table-rspace: 0pt;
            border-collapse: collapse !important;
          "
        >
          <tr>
            <td
              style="
                padding: 20px;
                -webkit-text-size-adjust: 100%;
                -ms-text-size-adjust: 100%;
                mso-table-lspace: 0pt;
                mso-table-rspace: 0pt;
              "
            >
              <!-- UNSUBSCRIBE COPY -->
              <table
                width="500"
                border="0"
                cellspacing="0"
                cellpadding="0"
                align="center"
                class="responsive-table"
                style="
                  -webkit-text-size-adjust: 100%;
                  -ms-text-size-adjust: 100%;
                  mso-table-lspace: 0pt;
                  mso-table-rspace: 0pt;
                  border-collapse: collapse !important;
                "
              >
                <tr>
                  <td
                    align="center"
                    valign="middle"
                    style="
                      font-size: 20px;
                      line-height: 11px;
                      font-family: Helvetica, Arial, sans-serif;
                      color: #666666;
                      -webkit-text-size-adjust: 100%;
                      -ms-text-size-adjust: 100%;
                      mso-table-lspace: 0pt;
                      mso-table-rspace: 0pt;
                    "
                  >
                    <p
                      class="appleFooter"
                      style="color: #cecece; margin: 0px; font-size: 15px"
                    >
                      © Durbanville Hills Winery
                    </p>
                    <br />
                    <p
                      class="original-only"
                      style="
                        font-family: Arial, sans-serif;
                        font-size: 12px;
                        color: #cecece;
                        margin-top: 0px;
                      "
                    >
                      Tygerberg Valley Rd, Cape Farms, Cape Town, 7550
                    </p>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
</body>
`;

export default displayEmail;
