import React, { FC, ReactNode } from 'react';

import './styles.css';

interface Proptypes {
  children: ReactNode;
  disabled?: boolean;
  outline?: boolean;
  handleOnClick?: (dir?: any) => void;
  type?: 'button' | 'submit' | 'reset' | undefined;
  small?: boolean;
}

const Button: FC<Proptypes> = ({
  children,
  disabled,
  outline,
  handleOnClick,
  type,
}) => {
  return (
    <button
      className={`mdl-button button ${outline ? 'outline' : ''}`}
      disabled={disabled}
      onClick={handleOnClick}
      type={type}
    >
      {children}
    </button>
  );
};

export const RoundButton: FC<Proptypes> = ({
  children,
  disabled,
  handleOnClick,
  type,
  small,
}) => {
  return (
    <button
      className={`mdl-button mdl-js-button mdl-button--fab round-button ${
        small && 'mdl-button--mini-fab'
      }`}
      disabled={disabled}
      onClick={handleOnClick}
      type={type}
    >
      {children}
    </button>
  );
};

export default Button;
