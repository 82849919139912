const toBase64 = async (url: string) => {
  const blob = await fetch(url).then((r) => r.blob());

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64String = reader.result;
      resolve(base64String);
    };
    reader.onerror = () => {
      reject('Failed convert image to base 64');
    };
  });
};

export default toBase64;
