import React, { FC } from 'react';
import { Swipeable } from './DeckSwiper';
import { Cocktail } from '../../Models/cocktail';

import CocktailCard from '../CocktailCard';
import directionEnum from '../../Constants/direction';

interface PropTypes {
  renderButtons?: any;
  cocktails: Cocktail[];
  handleOnSwipe: (
    forceSwipe: (direction: directionEnum) => void,
    cancelSwipe: () => void,
    direction: directionEnum
  ) => void;
  currentIndex: number;
  handleOnSwipedOnly?: (direction: directionEnum) => void;
}
const CocktailSwipe: FC<PropTypes> = ({
  renderButtons,
  cocktails,
  handleOnSwipe,
  currentIndex,
  handleOnSwipedOnly,
}) => {
  return (
    <Swipeable
      renderButtons={renderButtons}
      fadeThreshold={1}
      swipeThreshold={100}
      onBeforeSwipe={handleOnSwipe}
      handleOnSwipedOnly={handleOnSwipedOnly}
    >
      <CocktailCard cocktail={cocktails[currentIndex]} />
    </Swipeable>
  );
};

export default CocktailSwipe;
