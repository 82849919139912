import React, { FC, useEffect, useState } from 'react';
import LazyLoad from 'react-lazyload';

import itsAmatchTitle from '../../Assets/images/its-a-match-title.png';
import faceBookIcon from '../../Assets/images/facebook-icon.png';
import { Cocktail } from '../../Models/cocktail';
import { Text } from '../Typography';
import EmailCocktail from '../EmailCocktail';
import { User } from '../../Models/user';
import VideoPlayer from '../VideoPlayer';
import HeartEmoji from '../Emojis';
import Button from '../Button';
import Loader from '../Loader';
import './match.css';

interface Proptypes {
  cocktail?: Cocktail;
  user?: User;
  handleClose: () => void;
}
const Match: FC<Proptypes> = ({ cocktail, handleClose, user }) => {
  const [videoMode, setVideoMode] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [loading, setLoading] = useState(true);
  const faceBookImage = (
    <img className="thanks-for-playing-fb" src={faceBookIcon} alt="logo" />
  );

  useEffect(() => {
    if (loading) {
      catchImages();
    }
  }, [loading]);

  const catchImages = async () => {
    const promises: any = await [itsAmatchTitle]?.map((item) => {
      return new Promise((resolve: any, reject: any) => {
        const img = new Image();

        img.src = item;
        img.onloadeddata = resolve();
        img.onerror = reject();
      });
    });
    await Promise.all(promises);
    setLoading(false);
  };

  const share = () => {
    window.FB.ui(
      {
        href: `${process.env.REACT_APP_LIVE_URL}/cocktail/`,
        method: 'share',
        quote: `I played Cocktail Finder & matched up with ${cocktail?.title.toUpperCase()}.
        Which Durbanville Hills Honeysuckle Cocktail is the perfect match for you? Play now to find out. ${process.env.REACT_APP_LIVE_URL} #HeyHoneysuckle #DrinkDHResponsibly`,
        hashtag: ['#HeyHoneysuckle', ' #DrinkDHResponsibly']
      },
      (res: any) => {}
    );
  };

  return (
    <div className="match-root">
      {!loading ? (
        <>
          <LazyLoad>
            <img
              className="match-logo animate__animated animate__bounceIn"
              src={itsAmatchTitle}
              alt="match"
            />
          </LazyLoad>
          <div className="match-faces-root">
            <div className="match-subtitle">
              <Text>
                {user?.name || 'You'} and {cocktail?.title} have liked each
                other
              </Text>
            </div>
            <div className="match-faces">
              {user && user.picture ? (
                <LazyLoad>
                  <img
                    className="match-user material-icons match-cocktail"
                    alt="cocktail-name"
                    src={user.picture}
                  />
                </LazyLoad>
              ) : (
                <span className="match-user material-icons">
                  account_circle
                </span>
              )}
              <HeartEmoji large={true} />
              <LazyLoad>
                <img
                  className="match-user material-icons match-cocktail"
                  alt="cocktail-name"
                  src={cocktail?.image}
                />
              </LazyLoad>
            </div>
          </div>

          <div className="landing-actions-container">
            <Button outline={true} handleOnClick={() => setVideoMode(true)}>
              Watch video
            </Button>
            <Button outline={true} handleOnClick={() => setSendingEmail(true)}>
              Get the recipe
            </Button>
            <Button outline={true} handleOnClick={handleClose}>
              Keep playing
            </Button>
          </div>
          {sendingEmail && (
            <EmailCocktail
              open={sendingEmail}
              handleClose={() => setSendingEmail(false)}
              user={user}
              cocktail={cocktail}
            />
          )}

          {videoMode && cocktail ? (
            <VideoPlayer
              handleClose={() => setVideoMode(false)}
              videoId={cocktail?.video}
            />
          ) : (
            <></>
          )}

          <div className="match thanks-for-playing-footer">
            {/* eslint-disable-next-line  */}
            <a target="_blank" rel="noopener noreferrer" onClick={share}>
            <span>Share on</span> {faceBookImage}
            </a>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default Match;
