import React from 'react';
import LazyLoad from 'react-lazyload';

import logo from '../../Assets/images/dvh-logo.png';
import './styles.css';

export const Logo = () => {
  return (
    <LazyLoad>
      <img className="logo" src={logo} alt="logo" />
    </LazyLoad>
  );
};

export default Logo;
