import React, { FC } from 'react';
import Modal from '../Modal';

import './style.css';

interface Proptypes {
  fullscreen?: boolean;
}
const Loader: FC<Proptypes> = ({ fullscreen }) => {
  const loaderComponent = (
    <div className="loader-root">
      <div className="lds-ring">
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );

  if (fullscreen) {
    return (
      <Modal open={true}>
        <div className="lds-ring">
          <div />
          <div />
          <div />
          <div />
        </div>
      </Modal>
    );
  }

  return loaderComponent;
};

export default Loader;
