import React, { FC } from 'react';

import heartIcon from '../../Assets/images/heart-icon.png';
import closeIcon from '../../Assets/images/close-icon.png';
import rightArrow from '../../Assets/images/right-arrow.png';
import leftArrow from '../../Assets/images/left-arrow.png';
import './styles.css';

interface PropTypes {
  large?: boolean;
}
export const HeartEmoji: FC<PropTypes> = ({ large }) => {
  return (
    <img
      className={`emoji ${large && 'large-emoji'}`}
      src={heartIcon}
      alt="💛"
    />
  );
};

export const CloseEmoji: FC<PropTypes> = ({ large }) => {
  return (
    <img
      className={`emoji ${large && 'large-emoji'}`}
      src={closeIcon}
      alt="💛"
    />
  );
};

export const RightArrow = () => {
  return <img className="right-arrow" src={rightArrow} alt="◀️" />;
};

export const LeftArrow = () => {
  return <img className="left-arrow" src={leftArrow} alt="◀️" />;
};

export default HeartEmoji;
