import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { COCKTAIL, LANDING, SIGN_UP } from './Constants/route';
import Landing from './Layouts/Landing';
import Signup from './Layouts/Signup';
import Cocktails from './Layouts/Cocktails';
import './App.css';
import AuthProvider from './Firebase/Auth';
import { HelmetProvider } from 'react-helmet-async';
import Head from './Components/Head';

const App = () => {
  return (
    <div className="App">
      <HelmetProvider>
        <Head />
        <AuthProvider>
          <Router>
            <Switch>
              <Route exact={true} path={LANDING} component={Landing} />
              <Route path={COCKTAIL} component={Cocktails} />
              <Route path={SIGN_UP} component={Signup} />
            </Switch>
          </Router>
        </AuthProvider>
      </HelmetProvider>
    </div>
  );
};

export default App;
