import { Cocktail } from '../Models/cocktail';

export const formatHashKey = (key: string) => {
  return key.trim().replace(' ', '_').toLowerCase();
};

const getHashCocktail = (
  key: string,
  cocktails: Cocktail[]
): Cocktail | null => {
  let currentCocktail: Cocktail | null = null;
  const tempKey = key.replace('#', '');

  cocktails.forEach((item: Cocktail) => {
    if (formatHashKey(item.title) === tempKey) {
      currentCocktail = item;
    }
  });

  return currentCocktail;
};

export default getHashCocktail;
