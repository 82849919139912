import React, { ReactChild, FC, useState, useEffect } from 'react';

import UserContext from './context';

interface Proptypes {
  children: ReactChild;
}

const localState = JSON.parse(`${localStorage.getItem('user') || null}`);

const AuthProvider: FC<Proptypes> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<null | any>(localState);

  useEffect(() => {
    localStorage.setItem('user', JSON.stringify(currentUser));
  }, [currentUser]);

  return (
    <UserContext.Provider value={{ currentUser, setCurrentUser }}>
      {children}
    </UserContext.Provider>
  );
};

export default AuthProvider;
