import React, { FC, useCallback, useContext, useEffect, useState } from 'react';

import finderLogo from '../../Assets/images/finder-logo.png';
import ThanksForPlaying from '../../Components/ThanksForPlaying';
import CocktailSwipe from '../../Components/CocktailSwipe';
import { RoundButton } from '../../Components/Button';
import directionEnum from '../../Constants/direction';
import UserContext from '../../Firebase/context';
import { Cocktail } from '../../Models/cocktail';
import Match from '../../Components/Match';
import Logo from '../../Components/Logo';
import HeartEmoji, {
  CloseEmoji,
  LeftArrow,
  RightArrow,
} from '../../Components/Emojis';
import { addCocktail, getCocktails } from '../../Firebase/service';
import './cocktails.css';
import Loader from '../../Components/Loader';
import { useHistory } from 'react-router-dom';
import Head from '../../Components/Head';
import getHashCocktail, { formatHashKey } from '../../Helper/getHashCocktail';

const Cocktails: FC = () => {
  const [index, setIndex] = useState(0);
  const [cocktails, setCockTails] = useState<Cocktail[] | null>(null);
  const [matched, setMatched] = useState<Cocktail | null>(null);
  const { currentUser, setCurrentUser } = useContext(UserContext);
  const [loader, setLoader] = useState(false);
  const history = useHistory();
  const hash = history.location?.hash;

  const getGameCocktails = useCallback(() => {
    setLoader(true);
    getCocktails()
      .then((data: any) => {
        setLoader(false);
        setCockTails(data);
      })
      .catch((err) => {
        setCurrentUser(null);
        history.push('/');
      });
  }, [history, setCurrentUser]);
  /**
   * Filter new cocktails
   */
  useEffect(() => {
    if (cocktails) {
      if (index >= cocktails.length) {
        setIndex(0);
      }

      if (index === -1) {
        setIndex(cocktails.length - 1);
      }
    } else {
      getGameCocktails();
    }
  }, [cocktails, getGameCocktails, index]);

  const handleOnSwipe = (
    forceSwipe: any,
    cancelSwipe: any,
    swipeDirection: unknown
  ) => {
    forceSwipe();
  };

  const handleAddCocktails = (direction: directionEnum) => {
    if (cocktails) {
      if (direction === directionEnum.RIGHT) {
        setMatched(cocktails[index]);
        if (currentUser.email || currentUser.userID) {
          addCocktail(currentUser, cocktails[index]);
        }
      }
      removeCocktail();
      handleSkip();
    }
  };

  const removeCocktail = () => {
    setCockTails((prev) => {
      const temp = prev;
      if (temp && temp[index]) {
        temp.splice(index, 1);
      }
      return temp;
    });
  };

  const handleLikeCocktail = (swipe: any) => {
    swipe();
    handleAddCocktails(directionEnum.RIGHT);
  };

  const handleDislikeCocktail = (swipe: any) => {
    swipe();
    handleAddCocktails(directionEnum.LEFT);
  };

  const handleOnSwipedOnly = (direction: directionEnum) => {
    handleAddCocktails(direction);
  };

  const handleSkip = (move?: any) => {
    let tempIndex = index;
    tempIndex++;
    setIndex(tempIndex);
    if (move) {
      move();
    }
  };

  const handleReturn = (move?: any) => {
    let tempIndex = index;
    tempIndex--;
    setIndex(tempIndex);
    if (move) {
      move();
    }
  };

  const renderButtons: any = ({ right, left }: any) => (
    <div className="cocktails-action">
      <RoundButton small={true} handleOnClick={() => handleReturn(left)}>
        <LeftArrow />
      </RoundButton>

      <RoundButton handleOnClick={() => handleDislikeCocktail(left)}>
        <CloseEmoji large={true} />
      </RoundButton>
      <RoundButton handleOnClick={() => handleLikeCocktail(right)}>
        <HeartEmoji large={true} />
      </RoundButton>

      <RoundButton small={true} handleOnClick={() => handleSkip(right)}>
        <RightArrow />
      </RoundButton>
    </div>
  );

  if (matched) {
    return (
      <>
        <Match
          cocktail={matched}
          user={currentUser}
          handleClose={() => setMatched(null)}
        />
      </>
    );
  }

  if (loader) {
    return (
      <div className="cocktails-root cocktail-loading">
        <img className="cocktail-logo" src={finderLogo} alt="logo" />
        <Logo />
        <Loader />
      </div>
    );
  }

  const currentCocktailHash: Cocktail | null =
    cocktails && hash ? getHashCocktail(hash, cocktails) : null;

  return (
    <>
      {cocktails && currentCocktailHash ? (
        <Head
          title={
            cocktails &&
            `Durbanville Hills ${currentCocktailHash.title} Cocktail`
          }
          image={currentCocktailHash.image}
          description={currentCocktailHash.description}
          link={`http://dvh-cocktail-finder.netlify.app/cocktail#${formatHashKey(
            currentCocktailHash.title
          )}`}
        />
      ) : (
        <></>
      )}
      {cocktails && cocktails.length !== 0 ? (
        <div className="cocktails-root">
          <img className="cocktail-logo" src={finderLogo} alt="logo" />
          <Logo />
          <CocktailSwipe
            handleOnSwipe={handleOnSwipe}
            cocktails={cocktails}
            renderButtons={renderButtons}
            currentIndex={index}
            handleOnSwipedOnly={handleOnSwipedOnly}
          />
        </div>
      ) : (
        <ThanksForPlaying newGame={() => setCockTails(null)} />
      )}
    </>
  );
};

export default Cocktails;
