import firebase from 'firebase';
import removeExisting from '../Helper/removeExisting';
import { Cocktail } from '../Models/cocktail';
import { User } from '../Models/user';
import { db } from './firebase';

export const addCocktail = async (user: User, cocktail: Cocktail) => {
  const sessionRef = db.collection('session');
  const existingSession = await sessionRef
    .where('user.userID', '==', user.userID)
    .get();

  if (!existingSession.empty) {
    if (!user.gameId) {
      return sessionRef
        .doc(`${existingSession.docs[0].id}`)
        .collection('games')
        .add({
          cocktails: { [cocktail.title]: cocktail },
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
    }

    return sessionRef
      .doc(`${existingSession.docs[0].id}`)
      .collection('games')
      .doc(`${user.gameId}`)
      .update({
        [`cocktails.${cocktail.title}`]: cocktail,
      });
  }

  return sessionRef
    .add({
      user,
    })
    .then((res) =>
      db.collection(`session/${res.id}/games`).add({
        cocktails: { [cocktail.title]: cocktail },
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        
      })
    );
};

export const createGame = async (user: User) => {
  const sessionRef = db.collection('session');
  const existingSession = await sessionRef
    .where('user.userID', '==', user.userID)
    .get();

  if (existingSession.empty) {
    return;
  }

  return sessionRef
    .doc(`${existingSession.docs[0].id}`)
    .collection('games')
    .add({
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
};

export const getCocktails = () => {
  return db
    .collection('cocktails')
    .get()
    .then((querySnapshot) => querySnapshot.docs.map((doc) => doc.data()));
};

export const getUserCocktail = async (user: User) => {
  const sessionRef = db.collection('session');

  if (!user || !user.userID) {
    return;
  }

  const existingSession = await sessionRef
    .where('user.userID', '==', user.userID)
    .get();
  let results: any = null;

  if (!existingSession.empty) {
    try {
      const data: any = await existingSession.docs.map((item) => item.data());
      results = data
        ? removeExisting(Object.values(data[0].cocktails), [])
        : [];
    } catch (err) {}
  }

  return results || [];
};
