import React, { useContext, useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { COCKTAIL } from '../../Constants/route';
import UserContext from '../../Firebase/context';
import faceboookAuth from '../../Firebase/facebookAuth';
import './styles.css';

export const FacebookButton = () => {
  const [userData] = useState({
    loggedIn: false,
    name: '',
    email: '',
    picture: '',
  });
  const { currentUser, setCurrentUser } = useContext(UserContext);
  const history = useHistory();
  let fbContent;

  useEffect(() => {
    if (currentUser && currentUser.userID) {
      history.push(COCKTAIL);
    }
  }, [currentUser, history]);

  const responseFacebook = async () => {
    const auth = await faceboookAuth();

    if (auth) {
      setCurrentUser({
        name: auth.displayName,
        userID: auth.email || auth.uid,
        picture: auth.photoURL,
        subscriber: false,
        email: auth.email || null,
        loggedIn: true,
      });
    }
  };

  if (userData.loggedIn) {
    fbContent = null;
  } else {
    fbContent = (
      <button id="customBtn" onClick={responseFacebook}>
        <span className="icon fa fa-facebook">
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fab"
            data-icon="facebook"
            className="svg-inline--fa fa-facebook fa-w-16"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              fill="#fff"
              d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"
            ></path>
          </svg>
        </span>
        <span className="buttonText">Continue with Facebook</span>
      </button>
    );
  }
  return fbContent;
};

export default FacebookButton;
