import React, { FC, ReactNode } from 'react';
import ReactModal from 'react-modal';

import './modal.css';

interface Proptypes {
  open: boolean;
  children: ReactNode;
  handleOnClose?: () => void;
}
const Modal: FC<Proptypes> = ({ open = false, children, handleOnClose }) => {
  const modalStyles = {
    backgroundColor: 'rgb(64 64 64 / 61%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  return (
    <ReactModal
      style={{ overlay: modalStyles }}
      shouldCloseOnOverlayClick={true}
      onRequestClose={handleOnClose}
      className="mdl-dialog modal-root"
      isOpen={open || false}
      ariaHideApp={false}
    >
      {children}
    </ReactModal>
  );
};

export default Modal;
