import React, { FC, useEffect, useState } from 'react';

import finderLogo from '../../Assets/images/finder-logo.png';
import { Cocktail } from '../../Models/cocktail';
import { SmallText, Title } from '../Typography';
import './cocktail-card.css';

interface Proptypes {
  cocktail: Cocktail;
}
const CocktailCard: FC<Proptypes> = ({ cocktail }) => {
  const [expand, setExpand] = useState(false);

  useEffect(() => {});

  const toggleExpand = () => {
    setExpand(!expand);
  };

  if (!cocktail) {
    return <></>;
  }

  return (
    <div className="cocktail-root animate__animated animate__fadeInTopLeft animate__delay-2s">
      <div className="cocktail-image-container">
        <div
          className="cocktail-image"
          style={{ backgroundImage: `url('${cocktail.image}')` }}
        />
      </div>
      <div className="cocktail-right">
        <img className="cocktail-finder__logo" src={finderLogo} alt="logo" />
        <Title>{cocktail.title}</Title>
        {expand ? (
          <div className="cocktail-expanded" onClick={toggleExpand}>
            <SmallText capitalize={false}>{cocktail.description}</SmallText>
          </div>
        ) : (
          <div className="cocktail-description" onClick={toggleExpand}>
            <SmallText capitalize={false}>{cocktail.description}</SmallText>
            <span className="cocktail-expand">see more</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default CocktailCard;
