import React, { FC, useContext } from 'react';

import finderLogo from '../../../Assets/images/finder-logo.png';
import bottleImage from '../../../Assets/images/bottle.png';
import faceBookIcon from '../../../Assets/images/facebook-icon.png';
import instagramIcon from '../../../Assets/images/instagram-icon.png';
import { InfoText, Text, Title } from '../../Typography';
import Logo from '../../Logo';
import Button from '../../Button';
import UserContext from '../../../Firebase/context';
import { createGame } from '../../../Firebase/service';
import './styles.css';

interface PropTypes {
  newGame: () => void;
}
const ThanksForPlayingMobile: FC<PropTypes> = ({ newGame }) => {
  const { currentUser, setCurrentUser } = useContext(UserContext);

  const restartGame = () => {
    if (currentUser.userID) {
      createGame(currentUser).then((res: any) => {
        setCurrentUser({ ...currentUser, gameId: res.id });
      });
    }
    newGame();
  };

  const faceBookImage = (
    <img className="thanks-for-playing-fb" src={faceBookIcon} alt="logo" />
  );

  const instagramImage = (
    <img className="thanks-for-playing-fb" src={instagramIcon} alt="logo" />
  );

  const share = () => {
    window.FB.ui(
      {
        href: `${process.env.REACT_APP_LIVE_URL}`,
        method: 'share'
      },
      (res: any) => {}
    );
  };

  return (
    <div className="thanks-for-playing-root mobile">
      <div className="thanks-for-playing-root-inner mobile">
        <div className="thanks-for-playing-titles mobile">
          <Logo />
          <div className="thanks-for-playing-upper ">
            <img
              className="thanks-for-playing-logo"
              src={finderLogo}
              alt="logo"
            />
            <Title>Thanks for playing</Title>
            <div className="thanks-for-playing-socials">
              {/* eslint-disable-next-line  */}
              <a target="_blank" rel="noopener noreferrer" onClick={share}>
              <span>Share on</span> {faceBookImage}
              </a>
            </div>
          </div>

          <div className="thanks-for-playing-btn">
            <Button handleOnClick={restartGame}>Play again</Button>
          </div>
          <div className="thanks-for-playing-footer mobile">
            <Text>follow us for more</Text>
            <InfoText>
              #HeyHoneysuckle{' '}
              <span role="img" aria-label="yellow-heart">
                💛
              </span>{' '}
              <span role="img" aria-label="bee">
                🐝
              </span>{' '}
              #DrinkDHResponsibly
            </InfoText>
            <InfoText>
              <a
                href="https://www.durbanvillehills.co.za/"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.durbanvillehills.co.za
              </a>
            </InfoText>
            <div className="footer-links">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://web.facebook.com/DurbanvilleHills"
              >
                {faceBookImage}
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/DurbanvilleHills/"
              >
                {instagramImage}
              </a>
            </div>
          </div>
        </div>

        <div className="thanks-for-playing-bottle mobile">
          <img
            className="thanks-for-playing-bottle"
            src={bottleImage}
            alt="thanks-for-playing-bottle"
          />
        </div>
      </div>
    </div>
  );
};

export default ThanksForPlayingMobile;
