import React, { FC } from 'react';
import { Helmet } from 'react-helmet-async';

interface Proptype {
  title?: string;
  description?: string;
  image?: string;
  link?: string;
  hashtag?: string;
}

const Head: FC<Proptype> = ({ title, description, image, link, hashtag }) => {
  // const facebookCard =
  //   'https://firebasestorage.googleapis.com/v0/b/dvh-cocktail-tinder.appspot.com/o/app%2Ffacebook-card.jpg?alt=media&token=b8c198dc-eed0-47e9-a6b5-3b6526bf5507';
  // // const currentUrl = 'http://dvh-cocktail-finder.netlify.app';

  return (
    <Helmet>
      <title>
        {title || 'Durbanville Hills Cocktail Finder | Find your match!'}
      </title>
      <meta
        property="title"
        name="title"
        content={
          title || 'Durbanville Hills Cocktail Finder | Find your match!'
        }
      />
      <meta
        name="description"
        content={
          description ||
          'Whether you’re into fancy, chilled, hipster or hustler, there’s a perfect Durbanville Hills Honeysuckle cocktail for you. Get swiping & start liking – play Cocktail Finder now!'
        }
      />
      <meta property="url" content={link || process.env.REACT_APP_LIVE_URL} />
      {/* <meta
        property="og:title"
        content={
          title || 'Durbanville Hills Cocktail Finder | Find your match!'
        }
      />
      <meta
        property="og:description"
        content={
          description ||
          'Whether you’re into fancy, chilled, hipster or hustler, there’s a perfect Durbanville Hills Honeysuckle cocktail for you. Get swiping & start liking – play Cocktail Finder now!'
        }
      />
      <meta property="og:url" content={link || process.env.REACT_APP_LIVE_URL} />
      <meta property="image" content={image || facebookCard} />
      <meta property="og:type" content="website" />
      <meta property="og:hashtag" content={hashtag || '#HeyHoneysuckle'} />
      <meta content="image/*" property="og:image:type" />
      <meta property="og:site_name" content="CocktailFinder" />
      <meta property="og:image" content={image || facebookCard} />
      <meta property="fb:app_id" content={process.env.REACT_APP_FACEBOOK_API_KEY} /> */}
    </Helmet>
  );
};

export default Head;
