import React, { FC, useContext } from 'react';
import { useHistory } from 'react-router';

import { Text, SmallText } from '../../Components/Typography';
import { CloseEmoji, HeartEmoji } from '../../Components/Emojis';
import finderLogo from '../../Assets/images/finder-logo.png';
import FacebookButton from '../../Components/FacebookButton';
import Button from '../../Components/Button';
import Logo from '../../Components/Logo';
import { auth } from '../../Firebase/firebase';
import UserContext from '../../Firebase/context';
import './styles.css';

const Landing: FC = () => {
  const history = useHistory();
  const { setCurrentUser } = useContext(UserContext);

  const fbAuth = async () => {
    try {
      const res = await auth.signInAnonymously();
      if (res.user) {
        setCurrentUser({ loggedIn: true });
        history.push('cocktail');
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="landing-root">
      <Logo />
      <div className="landing-intro">
        <img className="landing-logo__finder" src={finderLogo} alt="logo" />
        <Text>
          Swipe left or right or press {<CloseEmoji />} or {<HeartEmoji />} to
          find your perfect cocktail match
        </Text>
      </div>
      <SmallText>For a more personalised experience</SmallText>
      <div className="landing-actions-container">
        <div className="landing-action">
          <Button handleOnClick={fbAuth}>Play Game</Button>
        </div>

        <div className="landing-action">
          <FacebookButton />
        </div>
      </div>
    </div>
  );
};

export default Landing;
