import React, { FC, useCallback, useContext } from 'react';
import { Redirect } from 'react-router-dom';

import UserContext from '../../Firebase/context';
import { COCKTAIL } from '../../Constants/route';
import { auth } from '../../Firebase/firebase';
import Form from '../../Components/Form';
import { User } from '../../Models/user';
import Logo from '../../Components/Logo';
import './styles.css';

const Signup: FC = () => {
  const { currentUser, setCurrentUser } = useContext(UserContext);

  const fbAuth = useCallback(
    async (data) => {
      try {
        const res = await auth.signInAnonymously();
        if (res.user) {
          setCurrentUser({ loggedIn: true });
        }
      } catch (error) {
        console.error(error);
      }
    },
    [setCurrentUser]
  );

  const handleSubmit = (data: User) => {
    if (data) {
      fbAuth(data);
    }
  };

  if (currentUser && currentUser.userID) {
    return <Redirect to={COCKTAIL} />;
  }

  return (
    <div className="signup-root">
      <Logo />
      <Form handleSubmit={handleSubmit} />
    </div>
  );
};

export default Signup;
