import axios from 'axios';
import toBase64 from '../Helper/fileToBase64';
import { formatHashKey } from '../Helper/getHashCocktail';
import displayEmail from '../Layouts/EmailTemplate';
import { Cocktail } from '../Models/cocktail';

const data: any = (user: any, cocktail: Cocktail, file: string) =>
  JSON.stringify({
    headers: {
      to: { [user?.email]: user?.name },
      from: { 'noreply@durbanvillehills.co.za': 'Durbanville Hills' },
      subject: 'Your Honeysuckle cocktail recipe has arrived!',
    },
    body: {
      html: displayEmail(user, cocktail),
    },
    options: { track_opens: 1, track_links: 1, batch_send: 1 },
    attachments: file
      ? [
          {
            filename: `${formatHashKey(cocktail.title)}_recipe.png`,
            data: file ? file.split(',')[1] : '',
            content_id: '0',
          },
        ]
      : [],
  });

const sendEmail = async (user: any, cocktail: Cocktail) => {
  let file = null;

  try {
    file = await toBase64(cocktail.recipe);
  } catch (error) {
    console.log('base64', error);
  }

  return axios({
    method: 'post',
    url: 'https://external.everlytic.net/everlytic/sendTransactionalEmail',
    headers: {
      'Content-Type': 'application/json',
    },
    data: data(user, cocktail, file),
  });
};

export default sendEmail;
