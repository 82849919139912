import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { User } from '../../Models/user';
import { Cocktail } from '../../Models/cocktail';
import Modal from '../Modal';
import Form from '../Form';
import Loader from '../Loader';
import UserContext from '../../Firebase/context';
import { addCocktail } from '../../Firebase/service';
import { Text } from '../Typography';
import sendEmail from '../../service/sendEmail';
import './styles.css';

interface Proptypes {
  cocktail?: Cocktail;
  user?: User;
  handleClose: () => void;
  open: boolean;
}
const EmailCocktail: FC<Proptypes> = ({
  cocktail,
  handleClose,
  user,
  open,
}) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const { currentUser, setCurrentUser } = useContext(UserContext);
  const history = useHistory();

  const sendRecipe = useCallback(() => {
    if (cocktail && user?.userID && user?.email) {
      setLoading(true);
      addCocktail(user, cocktail)
        .then((res: any) => {
          setLoading(false);

          if (res?.id && !currentUser.gameId) {
            setCurrentUser({ ...currentUser, gameId: res.id });
          }
        })
        .catch((err) => {
          setCurrentUser(null);
          history.push('/');
          setLoading(false);
        });
    }
  }, [cocktail, currentUser, history, setCurrentUser, user]);

  const sendEmailHandler = useCallback(() => {
    if (cocktail && currentUser?.userID && currentUser?.email) {
      sendEmail(currentUser, cocktail)
        .then((res) => {})
        .catch((err) => {
          console.log('failed', err.toString());
        });
      setSuccess(true);
    }
  }, [cocktail, currentUser]);

  useEffect(() => {
    if (!success && !loading) {
      sendEmailHandler();
      sendRecipe();
    }
  });

  const setUser = (data: User) => {
    setCurrentUser({
      ...user,
      ...data,
      userID: data.userID || data.email,
      email: data.email || null,
    });
  };

  let content = (
    <>
      <div className="email-cocktail-close">
        <i className="material-icons" onClick={handleClose}>
          close
        </i>
      </div>
      <Form handleSubmit={setUser} />
    </>
  );

  if (success) {
    content = (
      <>
        <div className="mdl-dialog__content">
          <Text capitalize={true}>
            The recipe has been sent and will be in your inbox soon.
          </Text>
        </div>
        <div className="mdl-dialog__actions">
          <button
            type="button"
            className="mdl-button close"
            onClick={handleClose}
          >
            OK
          </button>
        </div>
      </>
    );
  }

  return (
    <Modal open={open} handleOnClose={handleClose}>
      {loading ? <Loader /> : content}
    </Modal>
  );
};

export default EmailCocktail;
