import { auth, fbProvider} from "./firebase";

const faceboookAuth = () => {
    return auth.signInWithPopup(fbProvider).then((res) => {
        return res.user;
    }).catch(err => {
        return err
    })
}

export default faceboookAuth
